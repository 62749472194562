import { RemixBrowser } from '@remix-run/react'
import posthog from 'posthog-js'
import * as React from 'react'
import { startTransition } from 'react'
import { hydrateRoot } from 'react-dom/client'

// function PosthogInit() {
// 	React.useEffect(() => {
// 		posthog.init('phc_W9ILIGHgwAVeQxb0Kk86c1DQBEJq4rT8Ov5staKA0Ce', {
// 			api_host: 'https://us.i.posthog.com',
// 			person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
// 		})
// 	}, [])

// 	return null
// }

// if (ENV.MODE === 'production' && ENV.SENTRY_DSN) {
// 	void import('./utils/monitoring.client.tsx').then(({ init }) => init())
// }

startTransition(() => {
	hydrateRoot(
		document,
		<React.StrictMode>
			<RemixBrowser />
			{/* <PosthogInit /> */}
		</React.StrictMode>,
	)
})
