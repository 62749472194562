import { parseWithZod } from '@conform-to/zod'
import {
	json,
	type ActionFunctionArgs,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Link } from '@remix-run/react'
import { z } from 'zod'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { buttonVariants } from '#app/components/ui/button.tsx'
import { Icon } from '#app/components/ui/icon.tsx'
import { login } from '#app/utils/auth.server.ts'
import {
	ProviderConnectionForm,
	providerNames,
} from '#app/utils/connections.tsx'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { cn } from '#app/utils/misc.tsx'
import { EmailSchema } from '#app/utils/user-validation.ts'
import { handleNewSession } from './login-admin.server.ts'

const LoginFormSchema = z.object({
	email: EmailSchema,
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
})

export async function loader({}: LoaderFunctionArgs) {
	// await requireFamilyAnonymous(request)
	return json({})
}

export async function action({ request }: ActionFunctionArgs) {
	// await requireFamilyAnonymous(request)
	const formData = await request.formData()
	checkHoneypot(formData)
	const submission = await parseWithZod(formData, {
		schema: (intent) =>
			LoginFormSchema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, session: null }
				const session = await login(data)
				if (!session) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Invalid email',
					})
					return z.NEVER
				}

				return { ...data, session }
			}),
		async: true,
	})

	if (submission.status !== 'success' || !submission.value.session) {
		return json(
			{ result: submission.reply() },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

	const { session, remember, redirectTo } = submission.value

	return handleNewSession({
		request,
		session,
		remember: remember ?? false,
		redirectTo,
	})
}

export default function LoginPage() {
	return (
		<div className="container relative flex h-[800px] flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
			<Link
				to="https://jpgapps.org"
				className={cn(
					buttonVariants({ variant: 'ghost' }),
					'absolute right-4 top-4 md:right-8 md:top-8',
				)}
			>
				JPGApps
			</Link>
			<div className="relative hidden h-full flex-col bg-muted text-white dark:border-r md:p-10 lg:flex">
				<div className="absolute inset-0 bg-zinc-900" />
				<div className="relative z-20 flex items-center text-lg font-medium">
					<Icon name="megaphone" className="mr-2 size-6" />
					JPG Announces
				</div>
				<div className="relative z-20 mt-auto">
					<blockquote className="space-y-2">
						<p className="text-lg">
							&ldquo;Be who you are and be that well to give honor to the Master
							Craftsman whose handiwork you are.&rdquo;
						</p>
						<footer className="text-sm">St. Francis de Sales</footer>
					</blockquote>
				</div>
			</div>
			<div className="flex h-[400px] flex-col gap-10 md:h-auto lg:p-8">
				<div className="relative z-20 flex items-center justify-center text-3xl font-medium md:hidden">
					<Icon name="megaphone" className="mr-2 size-10" />
					JPG Announces
				</div>
				<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
					<div className="flex flex-col space-y-2 text-center">
						<h1 className="text-2xl font-semibold tracking-tight">Login</h1>
						<p className="text-sm text-muted-foreground">
							Please log in with your school email address.
						</p>
					</div>
					<ul className="mt-5 flex flex-col gap-5 py-3">
						{providerNames.map((providerName) => (
							<li key={providerName}>
								<ProviderConnectionForm
									type="Login"
									providerName={providerName}
									redirectTo="/lookup"
								/>
							</li>
						))}
					</ul>
					<p className="px-8 text-center text-sm text-muted-foreground">
						By clicking continue, you agree to our{' '}
						<Link
							to="/terms"
							className="underline underline-offset-4 hover:text-primary"
						>
							Terms of Service
						</Link>{' '}
						and{' '}
						<Link
							to="/privacy"
							className="underline underline-offset-4 hover:text-primary"
						>
							Privacy Policy
						</Link>
						.
					</p>
				</div>
			</div>
		</div>
	)
}

export const meta: MetaFunction = () => {
	return [{ title: 'Login to JPG Announces' }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
